var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mullvadInfo
    ? _c("div", { staticClass: "mullvad-wrapper" }, [
        _vm.mullvadInfo.isMullvad
          ? _c("p", { staticClass: "status connected" }, [
              _c("span", [_vm._v("✔")]),
              _vm._v(" Connected"),
            ])
          : _c("p", { staticClass: "status not-connected" }, [
              _c("span", [_vm._v("✘")]),
              _vm._v(" Not Connected"),
            ]),
        _c("div", { staticClass: "connection-info" }, [
          _c("p", [
            _c("span", { staticClass: "lbl" }, [_vm._v("IP")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.mullvadInfo.ip)),
            ]),
          ]),
          _vm.mullvadInfo.host
            ? _c("p", [
                _c("span", { staticClass: "lbl" }, [_vm._v("Host")]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.mullvadInfo.host)),
                ]),
              ])
            : _vm._e(),
          _c("p", [
            _c("span", { staticClass: "lbl" }, [_vm._v("Owner")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.mullvadInfo.ownedBy)),
            ]),
          ]),
          _vm.mullvadInfo.serverType
            ? _c("p", [
                _c("span", { staticClass: "lbl" }, [_vm._v("Type")]),
                _c("span", { staticClass: "val" }, [
                  _vm._v(_vm._s(_vm.mullvadInfo.serverType)),
                ]),
              ])
            : _vm._e(),
          _c("p", [
            _c("span", { staticClass: "lbl" }, [_vm._v("Location")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.mullvadInfo.location)),
            ]),
          ]),
          _c("p", [
            _c("span", { staticClass: "lbl" }, [_vm._v("Blacklisted?")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.mullvadInfo.isBlacklisted ? "✘ Yes" : "✔ No")),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }